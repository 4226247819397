<template>
  <div id="customers">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Customer List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="info" v-if="user.Role==10" class="btn" @click="exportFile()"><i class="icon-filter-export"></i>Export</el-button>
          <el-button type="primary" class="btn" @click="openAddDialog(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-filter-add" v-else></i>Add New Customer</el-button
          >
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Date</div>
            <el-date-picker format="dd-MM-yyyy" prefix-icon="icon-calendarplus" v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date" end-placeholder="End date" clearable @clear="dateRange = []"> </el-date-picker>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Country</div>
            <el-select
              v-model="filter.CountryId"
              placeholder="Select"
              filterable
              @clear="
                filter.CountryId = null;
                filter.CityId = null;
              "
              clearable
            >
              <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">City</div>
            <el-select v-model="filter.CityId" filterable placeholder="Select" @clear="filter.CityId = null" clearable :disabled="filter.CountryId == null">
              <template v-if="getAllCountries.some((x) => x.country_id == filter.CountryId)">
                <el-option v-for="item in getAllCountries.find((x) => x.country_id == filter.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
              </template>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Channel</div>
            <el-select v-model="filter.Channel" placeholder="Select" clearable @clear="filter.Channel = null">
              <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'CustomerChannel').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Interest Product</div>
            <el-select v-model="filter.InterestedProductIds" multiple placeholder="Select" clearable>
              <el-option v-for="(item, index) in getProducts" :key="index" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Company Name</div>
            <el-input v-model="filter.CompanyName" placeholder="Company Name" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Account Number</div>
            <el-input placeholder="Search with Account Number" prefix-icon="el-icon-search" v-model="filter.AccountNumber" v-mask="'##########################'" clearable></el-input>
          </el-col>
          <el-col class="input-container" :sm="8">
            <div class="text">Industry</div>
            <el-select v-model="filter.IndustryId" placeholder="Select" v-if="getIndustries" clearable>
              <el-option v-for="(item, index) in getIndustries" :key="index" :value="item.ID" :label="item.Title"> </el-option>
            </el-select>
          </el-col>
          <el-col class="input-container" :sm="8" >
              <div class="text">Account Manager</div>
              <el-select v-model="filter.AccountManagerId" placeholder="Select">
                <el-option v-for="(item, index) in getUserList.filter((x) => x.Role==1 || x.Role==10)" :key="index" :value="item.ID" :label="item.FirstName + ' ' + item.LastName"> </el-option>
              </el-select>
            </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="customerList" :data="customerList" style="width: 100%" v-on:sort-change="sortHandler">
        <el-table-column prop="AccountNo" label="Account Number" width="180" sortable="custom"> </el-table-column>
        <el-table-column prop="CompanyName" label="Company Name" sortable="custom"></el-table-column>

        <el-table-column prop="FirstName" label="Name" sortable="custom">
          <template slot-scope="scope">
            <div v-if="user.Role==10">
              <span v-if="scope.row.FirstName != null && scope.row.LastName != null">{{ scope.row.FirstName +" "+ scope.row.LastName }}</span>
              <span v-else></span>
            </div>
            <div v-else>
              <span v-if="scope.row.FirstName != null && scope.row.LastName != null && scope.row.AccountManagerId==user.ID">{{ scope.row.FirstName +" "+ scope.row.LastName }}</span>
              <span v-else></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Account Manager">
          <template slot-scope="scope" v-if="getUserList.length > 0">
            <span v-if="scope.row.AccountManagerId != null"> {{ getUserList.find((x) => x.ID == scope.row.AccountManagerId).Title }}</span>
            <span v-else> No account manager yet </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="Location">
          <template slot-scope="scope">
            <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId) && getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId)!=null">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).cities.find((x) => x.city_id == scope.row.CityId).name}}, </span>
            <span v-if="getAllCountries.some((x) => x.country_id == scope.row.CountryId)">{{ getAllCountries.find((x) => x.country_id == scope.row.CountryId).country }} </span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" sortable="custom">
          <template slot-scope="scope">
            <span>Created Date : {{ $moment(scope.row.CreatedDateTime).format("DD-MM-YYYY") }}</span
            ><br />
            <span>Last Update : {{ $moment(scope.row.UpdatedDateTime).format("DD-MM-YYYY") }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Actions" width="160">
          <template slot-scope="scope">
            <el-dropdown v-if="user.Role==10" >
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="openAddDialog(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-else-if="user.Role==1 && scope.row.AccountManagerId==user.ID" >
              <el-button type="primary"> Actions<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span class="view-edit" @click="openAddDialog(scope.row.ID)"><i class="icon-pen-to-square-solid"></i> View & Edit</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="no-data-text">No Records Found.</div>
        </template>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize" style="width: 100px">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>

          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>

    <el-dialog top="5vh" :visible.sync="dialogVisible" :show-close="false" :destroy-on-close="true" :close-on-click-modal="false" custom-class="add-potential-customer">
      <addOrUpdateCustomersDialog :id="setCustomerId" @close="closeAddDialog()" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import addOrUpdateCustomersDialog from "../../components/addOrUpdateCustomersDialog";
export default {
  components: {
    addOrUpdateCustomersDialog,
  },
  data() {
    return {
      setCustomerId: null,
      filterActive: false,
      dialogVisible: false,
      setForm: {},
      dateRange: null,
      loading: false,
      timeout: null,
      requestQueue: [], // İstek sırasını tutan dizi
    };
  },
  async beforeMount() {
    await Promise.all([this.$store.dispatch("getIndustries"), this.$store.dispatch("getCustomersList"), this.$store.dispatch("getProductSamplesList", { Page: 1, PageSize: 999999, SearchTerm: null }), this.$store.dispatch("getUserList", { Page: 1, PageSize: 999999, SearchTerm: null })]);
  },
  mounted() {
    this.jQuery(".filter-container").hide();
  },
  methods: {
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    exportFile() {
      var label = "CustomersList.xlsx";
      this.$client
        .get("/Customer/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    arrowsRotate() {
      this.$store.dispatch("getCustomersList");
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.dateRange = null;
      this.filter.CountryId = null;
      this.filter.CityId = null;
      this.filter.Channel = null;
      this.filter.InterestedProductIds = null;
      this.filter.CompanyName = null;
      this.filter.AccountNumber = null;
      this.filter.LeadId = null;
      this.filter.IndustryId = null;
    },
    openAddDialog(id) {
      if (id != null) {
        this.loading = "openAddDialogLoading" + id;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setCustomerId = id;
        this.dialogVisible = true;
        this.loading = false;
      }, 500);
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    closeAddDialog(id) {
      this.setCustomerId = id;
      this.dialogVisible = false;
    },
    createNewProduct() {
      this.addProductList.push({
        width: null,
        length: null,
        height: null,
        productsName: null,
        print: null,
        quality: null,
        unitPrice: null,
        quantity: null,
      });
    },
    deleteNewProduct(index) {
      this.addProductList.splice(index, 1);
    },
    processRequest(newValue) {
      this.requestQueue.push(newValue); // Yeni isteği sıraya ekler

      // Henüz işlem yapılmıyorsa işlemi başlatır
      if (this.requestQueue.length === 1) {
        this.executeRequests();
      }
    },
    executeRequests() {
      // İşlem yapılacak asenkron işlevinizi burada tanımlayın
      // Örneğin, bir API çağrısı yapabilirsiniz
      this.filterSearch().then(()=>{
        this.processNextRequest();
      })
    },
    processNextRequest() {
      this.requestQueue.shift(); 

      if (this.requestQueue.length > 0) {
        this.executeRequests();
      }
    },
    async filterSearch(){
      console.log(this.requestQueue)
      await this.$store.dispatch("getCustomersList");
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    form() {
      return this.$store.getters.getCustomersForm;
    },
    getUserList() {
      return this.$store.getters.getUserList;
    },
    filter() {
      return this.$store.getters.getCustomersFilter;
    },
    totalCount() {
      return this.$store.getters.getCustomersTotal;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    getProducts() {
      return this.$store.getters.getProductsList;
    },
    getIndustries() {
      return this.$store.getters.getIndustries;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        if (this.dateRange != null && this.dateRange.length > 0) {
          this.filter.StartDate = this.dateRange[0];
          this.filter.EndDate = this.dateRange[1];
        } else {
          this.filter.StartDate = null;
          this.filter.EndDate = null;
          this.dateRange = null;
        }
      },
    },
    filter: {
      deep: true,
      immediate: true, // İlk değeri alırken tetiklenmesini sağlar
      handler: function(newValue) {
        console.log("handler çalıştı")
        this.processRequest(newValue);
      }
    }
    // filter: {
    //   deep: true,
    //   handler: async function () {
    //     await this.$store.dispatch("getCustomersList");
    //   },
    // },
  },
};
</script>

<style></style>
